import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    document.addEventListener('turbo:visit', this.onTurboVisit);
  }

  disconnect() {
    document.removeEventListener('turbo:visit', this.onTurboVisit);
  }

  onTurboVisit = (event) => {
    if (event.detail.action === 'restore') {
      // This hack is needed for zendesk to work with turbo. See #7730.
      // Force widget to be reloaded on restore visit (ie browser back/forward buttons)
      window.zEACLoaded = undefined;
    }
  };
}
