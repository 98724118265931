import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.onTurboSubmitEnd = this.onTurboSubmitEnd.bind(this);
    this.onTurboVisit = this.onTurboVisit.bind(this);
    document.addEventListener('turbo:submit-end', this.onTurboSubmitEnd);
    document.addEventListener('turbo:visit', this.onTurboVisit);
  }

  disconnect() {
    document.removeEventListener('turbo:submit-end', this.onTurboSubmitEnd);
    document.removeEventListener('turbo:visit', this.onTurboVisit);
  }

  onTurboVisit = (event) => {
    if (event.detail.action === 'advance') {
      this.resetScroll();
    }
  };

  onTurboSubmitEnd = (event) => {
    const { submitter } = event.detail.formSubmission;
    if (submitter && this.getTurboAction(submitter) === 'advance') {
      this.resetScroll();
    }
  };

  getTurboAction = (element) => element.dataset && element.dataset.turboAction;

  resetScroll = () => window.scrollTo(0, 0);
}
