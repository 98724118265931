/**
 * Return an URL string with properly escaped query params.
 * @param {string} urlPath a relative or absolute URL
 * @param {Object} queryParams an optional object containing query params to be appended to the URL
 * @returns {string} an absolute URL with the provided query params
 */
function buildUrl(urlPath, queryParams = {}) {
  const url = new URL(urlPath, window.location);
  Object.entries(queryParams)
    .filter(([, value]) => value !== undefined)
    .forEach(([key, value]) => url.searchParams.append(key, value));
  return url.toString();
}

export default buildUrl;
