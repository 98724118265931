/* eslint-disable no-underscore-dangle */
import onLoadAsync from '../shared/on_load_async';

function trackers() {
  function hydrateTrackersWith(data) {
    if (window.Klaro?.getManager().consents.matomo) {
      window._mtm.push(data);
    }
  }

  // Clic sur le bouton Télécharger mes données de la modal de la page conso
  document.querySelectorAll('[data-gtm-event=btn-ecppp-conso-download]').forEach((cta) => {
    const radioButtons = document.querySelectorAll('#modal-download_modes input[type="radio"]');
    const { pathname } = new URL(window.location.href);
    const [typeConso, defaultDate] = pathname.split(/(conso_glo|puissance_max)\//).slice(-2);
    const [defaultYear, defaultMonth, defaultDay] = defaultDate.split('/');
    const consoRegex = /^\/consommation\/conso_glo\/\d{4}-\d{4}$/; // case of conso annuelle

    const date = consoRegex.test(pathname) ? defaultYear : new Date(`${defaultYear}-${defaultMonth || '01'}-${defaultDay || '01'}T00:00:00Z`).toJSON().split('T').shift();

    let dateRange = `[${date}]`;
    let downloadMode = 'current';

    radioButtons.forEach((radioButton) => {
      radioButton.addEventListener('click', () => {
        let dateMin = null;
        let dateMax = null;
        dateRange = `[${date}]`;
        if (radioButton.checked && (radioButton.value === 'chosen')) {
          const inputSmallStartsOn = radioButton.nextElementSibling.querySelector('label[for="starts_on"]');
          const inputSmallEndsOn = radioButton.nextElementSibling.querySelector('label[for="ends_on"]');
          dateMin = inputSmallStartsOn.nextElementSibling.value;
          dateMax = inputSmallEndsOn.nextElementSibling.value;
          dateRange = `[${dateMin} - ${dateMax}]`;
        }
        downloadMode = radioButton.value;
      });
    });

    cta.addEventListener('click', () => {
      hydrateTrackersWith({
        event: 'genericEvent',
        eventType: 'ctaDownload',
        getCategory: `Téléchargement ${typeConso} ${downloadMode}`,
        getAction: `Bouton Télécharger mes données ${typeConso} ${downloadMode} ${dateRange}`,
        getLabel: `Date : ${dateRange} - Type de conso : ${typeConso} - Période : ${downloadMode}`,
      });
    });
  });

  // Clic sur téléchargement de justificatif de domicile ("Accueil" / "encart_info page contract")
  document.querySelectorAll('[data-gtm-event=btn-ecppp-proof-of-address]').forEach((cta) => {
    cta.addEventListener('click', () => {
      hydrateTrackersWith({
        event: 'genericEvent',
        eventType: 'ctaProofOfAddress',
        getCategory: 'Téléchargement',
        getAction: 'Bouton justificatif de domicile',
        getLabel: 'Espace Client part. et petit pro',
      });
    });
  });

  // Clic sur téléchargement dernière facture ("/accueil")
  document.querySelectorAll('[data-gtm-event=btn-ecppp-invoice-welcome]').forEach((cta) => {
    cta.addEventListener('click', () => {
      hydrateTrackersWith({
        event: 'genericEvent',
        eventType: 'ctaclickInvoice',
        getCategory: 'Téléchargement',
        getAction: 'Bouton télécharger Dernière Facture sur Accueil',
        getLabel: 'Espace Client part. et petit pro',
      });
    });
  });

  // Clic sur téléchargement de facture annuel ("/factures")
  document.querySelectorAll('[data-gtm-event=btn-ecppp-invoice-yearly]').forEach((cta) => {
    cta.addEventListener('click', () => {
      hydrateTrackersWith({
        event: 'genericEvent',
        eventType: 'ctaclickInvoice',
        getCategory: 'Téléchargement',
        getAction: 'Bouton télécharger Facture Annuelle',
        getLabel: 'Espace Client part. et petit pro',
      });
    });
  });

  // Clic sur téléchargement de facture mensuel ("/factures")
  document.querySelectorAll('[data-gtm-event=btn-ecppp-invoice-monthly]').forEach((cta) => {
    cta.addEventListener('click', () => {
      hydrateTrackersWith({
        event: 'genericEvent',
        eventType: 'ctaclickInvoice',
        getCategory: 'Téléchargement',
        getAction: 'Bouton télécharger Facture Mensuelle',
        getLabel: 'Espace Client part. et petit pro',
      });
    });
  });

  // Visualisation de la consommation en euro
  document.querySelectorAll('#flex-switch-euros').forEach((cta) => {
    const { pathname } = new URL(window.location.href);
    const [dateConso] = pathname.split(/conso_glo\//).slice(-1);

    // conso quotidienne = yyyy/mm/dd
    // conso mensuelle = yyyy/mm
    // conso annuelle = yyyy-yyyy
    // NOTE: Date regexp mimic those in config/routes/date_constraints.rb
    let dateType;
    if (dateConso.match(/^2\d{3}\/(0[1-9]|1[0-2])$/)) {
      dateType = 'quotidienne sur un mois';
    } else if (dateConso.match(/^2\d{3}-2\d{3}$/)) {
      dateType = 'annuelle';
    } else if (dateConso.match(/^2\d{3}$/)) {
      dateType = 'mensuelle sur un an';
    }

    cta.addEventListener('change', (event) => {
      if (event.target.checked) {
        hydrateTrackersWith({
          event: 'genericEvent',
          eventType: 'changeToggle',
          getCategory: 'Consommation',
          getAction: `Conso en euro, ${dateType}`,
          getLabel: 'Espace Client part. et petit pro',
        });
      }
    });
  });
}

onLoadAsync(() => {
  if (window.initTrackers) window.initTrackers(trackers);
});
